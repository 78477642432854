import React from "react"
import PropTypes from "prop-types"
import SEO from "../components/seo"

import { graphql } from "gatsby"
import { LocaleContext } from "../components/layout"
import DetailView from "../components/Templates/detailView"

const Kontakt = ({ data: { content }, pageContext: { locale }, location }) => {
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]
  return (
    <>
      <SEO
        title={"Kontakt"}
        description={content.data.seo_kontakt_beschreibung.text}
        keywords={
          content.data.seo_kontakt_keywords.text
            ? content.data.seo_kontakt_keywords.text.split(", ")
            : [`Kontakt`, `Anfahrt`]
        }
      />
      <DetailView img={content.data.kontakt_bild}>
        <section id="kontakt">
          <div className="container">
            <div
              dangerouslySetInnerHTML={{
                __html: content.data.kontakt.html,
              }}
            />
          </div>
        </section>
      </DetailView>
    </>
  )
}

export default Kontakt

Kontakt.propTypes = {
  data: PropTypes.shape({
    content: PropTypes.object.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
}

export const pageQuery = graphql`
  query KontaktQuery($locale: String!) {
    content: prismicHomepage(lang: { eq: $locale }) {
      data {
        title {
          text
        }
        kontakt {
          html
        }
        seo_kontakt_beschreibung {
          text
        }
        seo_kontakt_keywords {
          text
        }
        kontakt_bild {
          alt
          url
          localFile {
            childImageSharp {
              fluid(maxWidth: 1200, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`
